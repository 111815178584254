.bulk-actions {
    position: fixed;
    background: map_get($red, primary);
    color: $white;
    left: 0;
    bottom: -$bulkActionsHeight;
    width: 100%;
    padding: 20px $bodyMargin;
    height: $bulkActionsHeight;
    opacity: 0;
    transition: opacity $duration $ease, bottom $duration $ease;
    animation-duration: $duration;
    animation-name: fade-in;
    z-index: 1;

    &.open {
        opacity: 1;
        bottom: 0;
    }

    &-wrapper {
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &-title {
        text-transform: uppercase;
        h2 {
            margin: 0 50px 0 0;
            font {
                weight: normal;
            }
        }
        p {
            margin: 5px 0 0;

            font-size: rem(14px);
            font-weight: normal;
        }
    }

    &-actions {
        li {
            position: relative;
            display: inline-block;
            margin-right: 30px;
        }
    }

    &-assign-list {
        display: none;
        position: absolute;
        bottom: 55px;
        width: auto;
        height: 300px;
        left: 0;
        background: $white;
        padding: 10px;
        border-radius: $articleRadius;
        border: 1px solid map_get($grey, light);
        white-space: nowrap;
        overflow: auto;
        box-shadow: $shadow;
        z-index: 1;
        animation-duration: $duration;
        animation-name: bulk-assign-appear;

        &.open {
            display: block;
        }


        li {
            margin: 10px 0;
            display: block;
            color: map_get($grey, primary);
            cursor: pointer;

            span.pin {
                margin-right: 10px;
            }
        }
    }
}
