.button {
    transition: color $duration $ease, border $duration $ease, background $duration $ease, transform $duration $ease;
    cursor: pointer;
    text-transform: uppercase;
    animation-duration: $duration;
    animation-name: fade-in;


    &.pils {
        padding: 10px 20px;
        border-radius: 18px;
        border: none;
        text-align: center;
        font: {
            weight: 400;
            size: rem(14px);
        };
        letter-spacing: 1px;
        white-space: nowrap;

        &.full{
            width: 100%;
        }

        &.big {
            padding: 20px 20px;
            border-radius: 40px;
            min-width: 200px;
        }

        &.x-big {
            padding: 20px 20px;
            border-radius: 40px;
            min-width: 300px;
        }

        &.small {
            padding: 5px 10px;
            border-radius: 18px;
            font-size: rem(13px);
        }

        &.transparent {
            background: transparent;
        }

        &.white-border {
            border: 1px solid $white;
            color: $white;
            text-transform: uppercase;

            &:hover {
                background: $white;
                color: map_get($red, primary);
            }
        }

        &.white {
            &:hover {
                background: map_get($red, primary);
                color: $white;
                border-color: map_get($red, primary);
            }
        }

        &.grey,
        &.gray {
            &:hover {
                background: map_get($red, primary);
                color: $white;
                border-color: map_get($red, primary);
            }
        }

        &.black-border {
            border: 1px solid $black;
            color: $black;
            text-transform: uppercase;

            &:hover {
                background: transparent;
                color: map_get($red, primary);
                border-color: map_get($red, primary);
            }
        }

        &.red {
            background: map_get($red, primary);
            border: 1px solid map_get($red, primary);
            color: $white;


            &:hover {
                background: $white;
                color: map_get($red, primary);
            }
        }

        &:disabled,
        &.disabled {
            background: map_get($grey, medium);
            border-color: map_get($grey, medium);
            color: $white;

            &:hover {
                background: map_get($grey, medium);
                border-color: map_get($grey, medium);
                color: $white;
                cursor: not-allowed;

            }
        }

        &.save {
            width: 55px;
            height: 55px;
            padding: {
                left: 0;
                right: 0;
            };
            padding: 20px 20px;
            border-radius: 40px;
            text-align: center;
            &:before {
                content: $icon-save;
                font: {
                    family: $icomoon;
                };
                line-height: 1;
            }
        }
    }

    &.icon {
        border: none;
        background: transparent;
        color: map_get($grey, primary);
        font: {
            family: $icomoon;
            weight: normal;
        }
    }
}

a.button {
    &.white {
        background: $white;
        color: map_get($grey, primary);
        border-color: $white;

        &:hover {
            background: map_get($red, primary);
            color: $white;
            border-color: map_get($red, primary);
        }
    }
}
