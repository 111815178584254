.grid {
    .wrapper {
        padding: 60px 80px;
    }
}

@import "bulk-actions";
@import "tools";
@import "search";
@import "collapse";
@import "form";
