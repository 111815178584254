.pagination {
  display: flex;
  align-items: center;

  &--fixed {
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 0;
  }

  &__list {
    display: flex;
    margin-right: 40px;

    .arrow {
      position: relative;
      width: 30px;
      height: 30px;
      background-color: $white;
      border-radius: 2px;
      border: none;

      &:hover {
        &:before {
          color: map_get($red, primary);
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font: {
          family: $icomoon;
          size: rem(10px)
        }
        color: map_get($grey, primary);
        transition: color $duration $ease;
      }

      &:disabled {
        background-color: map_get($grey, medium);

        &:before {
          color: $white;
        }
      }

      &.prev {
        margin-right: 10px;

        &:before {
          content: $icon-arrow-left;
        }
      }

      &.next {
        margin-left: 10px;
        &:before {
          content: $icon-arrow-right;
        }
      }
    }
  }

  &__pages {
    display: inline-flex;
  }

  &__page {

    &--current .pagination__link {
      pointer-events: none;
      color: map_get($red, primary);
    }
  }

  &__link {
    position: relative;
    padding: 10px;
    color: map_get($grey, primary);
    font: {
      weight: bold;
      size: rem(14px);
    }
    transition: color $duration $ease;

    &:hover {
      color: map_get($red, primary);

      &:before {
        opacity: 0.2;
      }
    }

    &:before {
      content: '';
      z-index: -1;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: map_get($red, primary);
      transform: translate(-50%, -50%);
      transition: opacity $duration $ease;
    }
  }
}

input[id^=pagination__input],
#pagination__input {
  width: 45px;
  height: 45px;
  padding: 10px;
  margin-right: 40px;
  text-align: center;
}
