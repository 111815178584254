.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - #{$headerHeight});
    text-align: center;

    p {
        margin: 30px 0 50px;
    }
}
