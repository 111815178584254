.draggable {
  overflow: hidden !important;
}

.fake-scrollbar {
  height: 1px;

  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-x: scroll;
  }
}