&-print {
    li {
        display: inline-block;
        cursor: pointer;
        font: {
            size: rem(12px);
            weight: bold;
        };
        text-transform: uppercase;
        transition: color $duration  ease-out;

        &:hover {
            color: map_get($red, primary);
        }

        &.disabled {
            color: map_get($grey, medium);

            &:hover {
                color: map_get($grey, medium);
                cursor: not-allowed;
            }
        }

        &:before {
            font: {
                family: $icomoon;
                size: rem(20px);
                weight: bold;
            }
            vertical-align: middle;
            margin-right: 10px;
        }


    }
    &-request {
        padding-right: 30px;
        border-right: 1px solid map_get($grey, medium);

        &:before {
            content: $icon-print;
        }
    }
    &-preview {
        padding-left: 30px;
        &:before {
            content: $icon-print;
        }
    }
}
