.tools {
    margin: {
        top: 40px;
    }
    width: 100vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: {
        left: 80px;
        right: 80px;
    };
    animation-duration: $duration;
    animation-name: fade-in;

    &-top {
        margin: {
            top: 60px;
            bottom: 20px;
        }
    }

    &-pagination {
        display: flex;
        align-items: center;
    }

    &-total {
        display: flex;
        font-size: rem(14px);
    }

    .export-new {
        li {
            display: inline-block;
            margin-left: 40px;
        }

        .tools-export {
            a,
            span {
                color: map_get($grey, primary);
                font: {
                    weight: bold;
                    size: rem(14px);
                }
                cursor: pointer;

                &:before {
                    display: inline-block;
                    margin-right: 20px;
                    content: $icon-export;
                    font: {
                        family: $icomoon;
                    }
                }
            }
        }

    }

    .limit {

        &__list {
            padding: 15px;
        }

        &__button {
            cursor: pointer;
            padding: 10px 0;
            border: 0;
            width: 100%;

            &--active,
            &:hover {
                color: map_get($red, primary);
                font-weight: bold;
            }
        }
    }
}
