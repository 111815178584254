.login {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    text-align: center;
    animation: fade-in .5s $ease 0s forwards;

    &--account {

        .login__form {
            margin: 0 -10px;
            width: 760px;
            display: flex;
        }

        form {
            padding: 0 10px;
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    &__wrapper {
        max-width: 760px;
    }

    &__title {
        margin-bottom: 70px;

        p {
            color: map_get($grey, xmedium);
            font-size: rem(18px);
            line-height: 1.8rem;
        }
    }

    input {
        border: none;
    }

    &__form {
        width: 365px;
        margin: 0 auto;

        .button {
            margin-top: 20px;
        }

        .errors {
            display: none;

            &.display {
                display: block;
            }
        }

        &-error {
            margin: 20px 0 0;
            color: map_get($red, primary);
        }
    }

    &__info {
        text-align: right;
        font-size: rem(13px);
    }

    &__button {
        display: inline-block;
    }

    &__notice {
        padding-top: 40px;
        font-size: rem(14px);
        color: map_get($red, primary);
    }
}

.login-options {
    padding-top: 80px;

    &__item {
        display: block;
        padding: 10px 0;
    }

    &__link {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: map_get($grey, xmedium);
        font-size: rem(12px);
        font-weight: bold;
        transition: color $duration $ease;

        &:hover {
            color: map_get($red, primary);

            &:before {
                background-color: map_get($red, primary);
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: map_get($grey, xmedium);
            transition: background-color $duration $ease;
        }
    }
}

.login-field {

    &--checkbox {
        text-align: left;

        .login-field__input {
            display: none;
        }
    }

    &--text {
        position: relative;
        display: block;
        width: 100%;
        margin: 20px auto 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.02);
        color: $black;
        font-size: rem(13px);
        background: $white;
        border-radius: $fieldRadius;
        font: {
            size: rem(13);
        }

        .login-field__label {
            display: block;
            position: absolute;
            left: 30px;
            top: 23px;
            transition: top $durationFast $ease;
            z-index: 1;
            line-height: 1;
        }
    }

    &__label {
        &.required {
            &:after {
                content: ' *';
            }
        }
    }

    &__input {
        display: block;
        position: relative;
        width: 100%;
        background: transparent;
        border: none;
        padding: 22px 30px;
        line-height: 16px;
        -webkit-appearance: none;
        z-index: 2;
    }

    &.focused {
        .login-field__label {
            top: -6px;
            z-index: 5;
        }
    }

    &.error {
        border: 1px solid map_get($red, primary);

        &.focused {
            .login-field__label {
                span {
                    position: relative;
                    padding: 0 5px;
                    z-index: 2;
                }
                &:after {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    bottom: 0;
                    background: $white;
                    content: '';
                }
            }
        }
    }
}
