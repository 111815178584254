.selector {
  z-index: 1;
  position: relative;
  padding: 0 5px;

  &--open {
    .selector__label {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font: {
      weight: bold;
    }

    &:after {
      margin-left: 15px;
      content: $icon-arrow-down;
      font: {
        family: $icomoon;
        size: rem(10px);
      }
      color: map_get($grey, primary);
      display: inline-block;
      transform: rotate(0deg);
      transition: transform $duration ease-out;
    }
  }

  &__content {
    position: absolute;
    margin-top: 20px;
    background: $white;
    border-radius: $articleRadius;
    box-shadow: $shadow;
    border: 1px solid #E5E5E5;
  }
}
