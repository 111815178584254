.tooltips {
    position: relative;
    color: $white;
    font-weight: normal;

    &.open {
        .tooltips-message {
            display: block;
        }
    }

    &-message {
        position: absolute;
        display: none;
        width: 280px;
        bottom: 20px;
        right: -20px;
        background: map_get($red, primary);
        padding: 10px 20px;
        border-radius: $tooltipRadius;
        z-index: 3;
        animation-duration: $duration;
        animation-name: fade-in;
        white-space: normal;
        text-align: left;
        line-height: 1.4;
        text-transform: none;

        &:after {
            display: block;
            position: absolute;
            content: '';
            height: 0;
            width: 0;
            border-right: 10px solid transparent;
            border-top: 10px solid map_get($red, primary);
            border-left: 10px solid transparent;
            bottom: -10px;
            right: 10px;
            transform: translateX(-100%);
        }
    }
}
