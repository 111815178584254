&-title {
    h2 {
        margin-top: 0;
        color: map_get($grey, primary);
        font: {
            weight: bold;
            size: rem(24px);
        }
        text-transform: none;
        line-height: rem(24px);;
        vertical-align: middle;
    }

    .pils {
        margin-left: 15px;
        line-height: rem(24px);;
        vertical-align: middle;
        display: inline-block;
        text-transform: capitalize;
    }
}
