@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker {
  font-family: $omegaCt;
  border: 1px solid map_get($grey, light);
  box-shadow: $shadow;
}

.react-datepicker__header {
  background-color: $white;
  border-bottom: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: map_get($red, primary);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: $white;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before  {
  border-bottom-color: map_get($grey, light);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: $white;
  border: 1px solid map_get($grey, light);
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: map_get($red, primary);
  color: $white;
}

.react-datepicker__navigation--next {
  border-left-color: map_get($grey, light);
}

.react-datepicker__navigation--previous {
  border-right-color: map_get($grey, light);
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: map_get($grey, light);
}

.react-datepicker__navigation--years-previous {
  border-top-color: map_get($grey, light);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: map_get($grey, light);
}