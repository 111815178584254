
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill
{
    // Remove background for autofilled
    //transition: background 9999s;
    border-radius: $fieldRadius;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.custom {
    &-checkbox,
    &-radio {
        display: none;

        & + label {
            &:before {
                content: '';
                display: inline-block;
                height: 15px;
                width: 15px;
                border: 1px solid map_get($grey, medium);
                vertical-align: text-top;
                line-height: inherit;
                margin-right: 15px;
            }
        }

        &:checked + label {
            &:before {
                border: 1px solid map_get($red, primary);
                background: map_get($red, primary);
            }
        }
    }
}


.form {
    .error-message {
        display: none;
        margin-top: 0;
        font-size: rem(11px);
        color: map_get($red, primary);
        text-align: left;

        &.show {
            display: block;
        }
    }
}
.field {
    display: inline-block;
    position: relative;
    margin: 5px 0;
    background: $white;
    border-radius: $fieldRadius;
    font: {
        size: rem(13);
    }

    &-label {
        display: block;
        position: absolute;
        left: 30px;
        top: 23px;
        transition: top $durationFast $ease;
        z-index: 1;
        line-height: 1;

        &.required {
            &:after {
                content: ' *';
            }
        }
    }

    &.focused {
        .field-label {
            top: -6px;
            z-index: 5;
        }
    }

    &-input {
        display: block;
        position: relative;
        width: 100%;
        background: transparent;
        border: none;
        padding: 22px 30px;
        line-height: 16px;
        -webkit-appearance: none;
        z-index: 2;
    }
    &.error {
        border: 1px solid map_get($red, primary);

        &.focused {
            .field-label {
                span {
                    position: relative;
                    padding: 0 5px;
                    z-index: 2;
                }
                &:after {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    bottom: 0;
                    background: $white;
                    content: '';
                }
            }
        }
    }
}

select {
    display: block;
    background: transparent;
    position: relative;
    width: 100%;
    //margin: 5px 0;
    padding: 22px 30px;
    background: $white url('../../img/arrow-down.png') center right 20px no-repeat;
    background-size: 14px 8px;
    border: 1px solid map_get($grey, light);
    border-radius: 0;
    line-height: 16px;
    appearance: none;
    font: {
        size: rem(16);
    }
}

:disabled {
    background: map_get($grey, xlight);
    cursor: not-allowed;
}

::placeholder {
    color: map_get($grey, light);
}

.inline-radio {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    label,
    input {
        height: auto;
        padding: {
            top: 0;
        }
    }

    label {
        margin: {
            right: 15px;
        }
    }
}

.prefix-radio {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border: 1px solid map_get($grey, light );

    &.disabled {
        background: map_get($grey, xlight);
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        border-left: 1px solid map_get($grey, light );
        width: 1px;
        height: 48px;
        top: 6px;
        left: calc(50% - 1px);

    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        border: 1px solid transparent;
        width: calc(50% - 8px);
        height: 47px;
        top: 4px;
        background: transparent;
        transition: left $duration $ease, right $duration $ease;
    }


    &.mr,
    &.mrs {
        &:before {
            display: none;

        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            border: 1px solid map_get($grey, light);
            width: calc(50% - 16px);
            height: 47px;
            top: 4px;
            background: map_get($grey, xlight);
            transition: left $duration $ease;
        }
    }
    &.mr:after {
        left: 5px;
    }
    &.mrs:after {
        left: calc(50% + 8px);
    }

    input[type=radio] {
        display: none;
    }

    label {
        display: block;
        margin-top: 0;
        line-height: 48px;
        text-align: center;
        width: calc(50% - 4px);
        z-index: 2;
    }

}

input[type=text],
input[type=number],
input[type=date],
input[type=email],
textarea,
.textarea,
.input {
    width: 100%;
    display: block;
    padding: 20px 30px;
    border: 1px solid map_get($grey, light );
    font: {
        size: rem(16px);
    }
    line-height: rem(16px);

    &.error {
        border: 1px solid map_get($red, primary);

        & + .error-message {
            display: block;
        }
    }
}

input[type=text],
input[type=number],
input[type=date],
input[type=email],
.input {
    height: 60px;
    display: block;

    &.inline-input {
        display: inline-block;
        width: auto;
        height: auto;
    }
    &.disabled,
    &:disabled {
        background: map_get($grey, xlight);
        cursor: not-allowed;
    }

    &.inline.small {
        display: inline-block;
        width: 56px;
        height: 40px;
        padding: 0 5px;
        vertical-align: middle;
        line-height: 35px;
        font-size: rem(14px);
        cursor: text;
    }
}
.label + .inline.small {
    margin-left: 0.5rem;
}

textarea,
.textarea {
    line-height: rem(24px);
    display: block;
    margin: 0;

    &.disabled,
    &:disabled {
        background: map_get($grey, xlight);
        cursor: not-allowed;
    }
}

.label {
    color: map_get($red, primary);
    font-weight: bold;
    font-size: rem(13px);
    text-transform: uppercase;
    padding-top: 40px;
    display: block;
    height: 70px;

    &.inline-label {
        display: inline-block;
        width: calc(90% - 10px);
        height: auto;
        margin-left: 10px;
        color: map_get($grey, primary);
        font: {
            size: rem(13px);
        }
    }
}

.instruction {
    padding-left: 5px;
    color: map_get($grey, medium);
    font: {
        weight: normal;
    }
    text-transform: none;
}

