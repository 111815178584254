.col-1 {
    article {
        width: 100%;
    }
}

.col-2 {
    article {
        width: calc(50% - 20px);
    }
}

&-details {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .white-block {
        margin-bottom: 0;
    }

    article {
        table {
            width: 100%;
            border-spacing: 0 10px;

            th {
                text-align: left;
                color: map_get($red, primary);
                font-weight: bold;
                font-size: rem(13px);
                text-transform: uppercase;
                padding-right: 20px;
            }

            td {
                //padding-right: 20px;
            }
        }
    }
}
&-information {
    h2 {
        margin-bottom: 5px;
    }

    h2 + p {
        margin-top: 0;
    }
}

&-image {

}

&-note,
&-research {
    textarea,
    .textarea {
        height: 200px;
    }
}

&-address {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

&-image-upload {

}

.form-col-full-textarea {
    display: flex;
    flex-direction: column;

    textarea,
    .textarea {
        flex: 1;
    }
}

#request_product_coaComments,
#request_product_coaComments-computed {
    overflow: hidden;
    padding-right: calc(100% - 568px);
    height: 330px;
}