.loading {
    opacity: 0;
    visibility: hidden;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    transition: opacity $duration $ease, visibility $duration $ease;

    img {
        display: block;
        width: 35px;
    }

    &--show {
        opacity: 1;
        visibility: visible;
    }
}

.locker {
    position: relative;
    transition: opacity $duration $ease;

    &--lock {
        pointer-events: none;
        opacity: 0.25;
    }
}