.modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    background: $white;
    z-index: 11;
    transform: translate(-50%, -50%);
    width: 550px;
    padding: 50px;
    animation-duration: $duration;
    animation-name: modal;


    &.open {
        display: block;
    }

    &.open + &-overlay {
        display: block;
    }


    &-overlay {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $black;
        opacity: $overlaydOpacity;
        z-index: 10;
        animation-duration: $duration;
        animation-name: overlay;
    }

    &-close {
        &:before {
            position: absolute;
            right: 25px;
            top: 25px;
            content: $icon-reject;
            font: {
                family: $icomoon;
                size: rem(11px);
            }
        }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }

    @at-root {
        .request {
            .modal {
                h2 {
                    color: map_get($red, primary);
                    margin-bottom: 40px;
                }

                label {
                    height: auto;
                    margin: {
                        top: 30px;
                        bottom: 15px;
                    }
                    padding-top: 0;
                    color: map_get($grey, primary);
                    text-transform: none;
                    font: {
                        weight: normal;
                        size: rem(14px);
                    }
                }

                textarea {
                    height: 120px;
                }

                button {
                    margin-top: 30px;
                    min-width: 200px;
                }
            }
        }
    }

}
