@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes appear {
    from {
        margin-top: 30px;
        opacity: 0;
    }

    to {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes header-submenu {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal {
    from {
        margin-top: 30px;
        opacity: 0;
    }

    to {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes overlay {
    from {
        opacity: 0;
    }

    to {
        opacity: $overlaydOpacity;
    }
}

@keyframes flashmessage {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes bulk-assign-appear {
    from {
        bottom: 85px;
        opacity: 0;
    }

    to {
        margin-top: 55px;
        opacity: 1;
    }
}
