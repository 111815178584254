.grid {

  .collapse {
    margin: 10px 0;
    border-radius: $articleRadius;
    background: $white;
    transition: box-shadow $duration $ease;

    &:hover {
      box-shadow: $shadow;
    }

    &.open > .collapse-header:before {
      transform: rotate(180deg);
    }

    &.close > .collapse-header:before {
      transform: rotate(0deg);
    }

    &-header {
      cursor: pointer;
      position: relative;
      padding: 30px 60px;
      font-size: rem(24px);
      letter-spacing: 2px;
      color: map_get($red, primary);
      text-transform: uppercase;

      &:before {
        content: $icon-triangle;
        position: absolute;
        right: 60px;
        top: 32px;
        font: {
          family: $icomoon;
          size: rem(24px);
        }
        color: map_get($red, primary);
        transition: transform $duration $ease;
      }
    }

    &-title {
      margin: 0;
    }

    &-body {
      height: 0;
      overflow: hidden;
    }
  }
}

