
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
    content: $icon-delete;
}
.icon-efta:before {
    content: $icon-efta;
}
.icon-back-watch:before {
    content: $icon-back-watch;
}
.icon-front-watch:before {
    content: $icon-front-watch;
}
.icon-interior-caseback:before {
    content: $icon-interior-caseback;
}
.icon-movement:before {
    content: $icon-movement;
}
.icon-add-file:before {
    content: $icon-add-file;
}
.icon-doc:before {
    content: $icon-doc;
}
.icon-pdf:before {
    content: $icon-pdf;
}
.icon-logout:before {
    content: $icon-logout;
}
.icon-profile:before {
    content: $icon-profile;
}
.icon-add-picture:before {
    content: $icon-add-picture;
}
.icon-arrow-up:before {
    content: $icon-arrow-up;
}
.icon-check:before {
    content: $icon-check;
}
.icon-long-arrow:before {
    content: $icon-long-arrow;
}
.icon-print:before {
    content: $icon-print;
}
.icon-reject:before {
    content: $icon-reject;
}
.icon-save:before {
    content: $icon-save;
}
.icon-zoom:before {
    content: $icon-zoom;
}
.icon-triangle:before {
    content: $icon-triangle;
}
.icon-more:before {
    content: $icon-more;
}
.icon-export:before {
    content: $icon-export;
}
.icon-calendar:before {
    content: $icon-calendar;
}
.icon-arrow-right:before {
    content: $icon-arrow-right;
}
.icon-arrow-left:before {
    content: $icon-arrow-left;
}
.icon-arrow-down:before {
    content: $icon-arrow-down;
}

