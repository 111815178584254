&-assign {
    position: relative;
    z-index: 1;

    &-title {

    }

    &-label {
        cursor: pointer;
        display: inline-block;
        position: relative;
        font: {
            weight: bold;
        }

        &.open {
            .request-assign-list {
                display: block;
            }
            .request-assign-name:after {
                transform: rotate(180deg);
            }
        }
    }

    &-name {
        &:after {
            margin-left: 15px;
            content: $icon-arrow-up;
            font: {
                family: $icomoon;
                size: rem(10px);
            }
            color: map_get($grey, primary);
            display: inline-block;
            transform: rotate(0deg);
            transition: transform $duration ease-out;
        }
    }

    &-list {
        position: absolute;
        top: 100%;
        left: 0;
        background: $white;
        padding: 10px;
        white-space: nowrap;
        display: none;
        height: 260px;
        overflow-y: auto;
        font-size: rem(13px);
        animation-duration: $duration;
        animation-name: appear;

        &.white-block {
            border: 1px solid map_get($grey, light);
            padding: 30px;
        }

        &-title {
            color: map_get($red, primary);
            text-transform: uppercase;
        }

        li {
            margin: 15px 0;
        }

        .pin {
            margin-right: 15px;
        }
    }
}
