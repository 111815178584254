nav.tab-panel {
    overflow: hidden;
    position: relative;
    background: map_get($red, primary);
    color: map_get($red, light);
    text-transform: uppercase;
    z-index: 2;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);


    ul {
        width: calc((100%/12)*10 + 40px);
        margin: 0 auto;

        li {
            display: inline-block;

            position: relative;
            margin: 0 20px;
            height: $navTabHeight;

            a {
                display: block;
                position: relative;
                line-height: $navTabHeight;
                font: {
                    family: $omegaCt;
                    weight: bold;
                }
                text-transform: uppercase;
                color: $white;
                opacity: .5;
                transition: color $duration $ease;

                &.active {
                    opacity: 1;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    background: transparent;
                    transition: height $duration $ease, background $duration $ease;
                }

                &.active,
                &:hover {

                    &:after {
                        height: 3px;
                        background: $white;
                    }
                }
            }
        }
    }
}

