// Fonts
$omegaCt: 'omegact', Arial, Helvetica, Sans-Serif;
$icomoon: 'icomoon';
// Duration
$durationFast: 0.15s;
$duration: 0.3s;
$durationSlow: 0.6s;
// Ease
$ease: ease-in-out;
// Shadow
$shadow: 0 0 10px 0 rgba(0,0,0,0.05);
// Body
$bodyMargin: 80px;
$maxWidth: 1600px;
$grid: 24;
$headerHeight: 70px;
// Flashmessage & bulkaction
$flashMessageHeight: 90px;
$bulkActionsHeight: 90px;
// Modal
$overlaydOpacity: 0.4;
// Grid
$tableRadius: 7px;
$gridRowHeight: 90px;
//Request
$navTabHeight: 60px;
$articleRadius: 8px;
$tooltipRadius: 8px;
// Form
$fieldRadius: 8px;
// Colors
$white: #ffffff;
$black: #000000;
$grey: (
    dark: #2D2D2D,
    primary: #555555,
    xmedium: #757575,
    medium: #CACACA,
    light: #E5E5E5,
    xlight: #F9F9F9
);
$red: (
    primary: #C40D2E,
    light: #F1DCE0
);
$green: (
    primary: #16C40D,
    secondary: #04B87F,
    light: #E0F5DD
);
$blue: (
    primary: #5388FC,
    light: #E7EDFE
);
$orange: (
    primary: #FF9000,
    light: #FDEFDB
);
$purple: (
    purple: #AE53FC,
);
$status: (
    warning: $orange,
    error: $red,
    info: $blue,
    success: $green,
    bulk-actions-flashmessage: $red,
);
$pilsStatus: (
    to_validate: $orange,
    wip: $red,
    new: $blue,
    assigned: $green,
    done: $grey
);
$userColors : (
    0: #5586a4,
    1: #88b14b,
    2: #ef562d,
    3: #d13076,
    4: #b39277,
    5: #f1c11c,
    6: #89cad6,
    7: #c40d2e,
    8: #555555,
    9: #62518c
);
// Icons
$icon-delete: "\e914";
$icon-efta: "\e915";
$icon-back-watch: "\e916";
$icon-front-watch: "\e917";
$icon-interior-caseback: "\e918";
$icon-movement: "\e919";
$icon-add-file: "\e911";
$icon-doc: "\e912";
$icon-pdf: "\e913";
$icon-logout: "\e90f";
$icon-profile: "\e910";
$icon-add-picture: "\e900";
$icon-arrow-up: "\e901";
$icon-check: "\e902";
$icon-long-arrow: "\e903";
$icon-print: "\e904";
$icon-reject: "\e905";
$icon-save: "\e906";
$icon-zoom: "\e907";
$icon-triangle: "\e908";
$icon-more: "\e909";
$icon-export: "\e90a";
$icon-calendar: "\e90b";
$icon-arrow-right: "\e90c";
$icon-arrow-left: "\e90d";
$icon-arrow-down: "\e90e";
