.request {
    position: relative;
    padding-top: 50px;
    width: 100vw;

    &.no-nav {
        padding-top: 0;
    }

    h2 {
        margin-top: 0;
        color: map_get($grey, medium);
        font: {
            weight: normal;
            size: rem(18px);
        }
        text-transform: uppercase;

    }

    table {
        &.full-width {
            width: 100%;
        }
        &.auto-width {
            width: auto;
        }
    }

    table .td-separator {
        width: 60px;
    }

    table.request-table {
        width: 100%;
        border-spacing: 0 10px;

        th {
            text-align: left;
            color: map_get($red, primary);
            font-weight: bold;
            font-size: rem(13px);
            text-transform: uppercase;
        }

        th,
        td {
            width: 50%;
        }

        td + td,
        th + th {
            padding-left: 20px;
        }
    }

    .separator {
        height: 20px;
    }

    .wrapper {
        width: (100%/12)*10;
        margin: 0 auto;
        position: relative;
    }

    .cancel-wrapper {
        margin: 0 calc((-100vw + (100vw/12) * 10) / 2);
    }

    .container {
        position: relative;
        margin: 40px 0;
    }

    .white-block {
        background: $white;
        padding: 60px;
        border-radius: $articleRadius;
        box-shadow: $shadow;
    }

    .form-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .form-col-1-3 {
        width: calc(100%/3 - 30px);
    }

    .form-col-2-3 {
        width: calc(200%/3 - 30px);
    }

    .shipping-billing-checkbox {
        padding-top: 40px;
    }

    .attachments {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        margin: -25px;

        &--images {
            .attachment--upload {
                .attachment__meta {
                    .attachment__icon {
                        font-size: 3.5rem;

                        .icon-more {
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }
    }

    .attachment {
        position: relative;
        width: 25%;
        padding: 25px;
        display: block;
        font: {
            size: rem(13px);
        }

        &--upload {

            .attachment__meta {
                .attachment__icon {
                    font-size: 6rem;
                    color: map_get($grey, medium);
                    transition: color $duration $ease;
                }

                &:before {
                    content: none;
                }
            }

            .attachment__meta {
                &:hover {
                    .attachment__icon {
                        color: map_get($red, primary);
                    }

                    .attachment__title {
                        color: $black;
                    }
                }
            }
        }

        &--doc,
        &--pdf {
            .attachment__inner {
                &:before {
                    content: '';
                }
            }

            .attachment__icon {
                font-size: 3rem;
            }
        }

        &--jpg,
        &--jpeg,
        &--png {
            .attachment__icon {
                color: $white;
                opacity: 0;
                visibility: hidden;
                transition: opacity $duration $ease, visibility $duration $ease;
            }

            .attachment__meta {
                &:hover {
                    .attachment__icon {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &__inner {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 76%;
            height: auto;
            background-color: map_get($grey, xlight);
        }

        &__link {
            width: 100%;

            &--lock {
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;

            animation: fade-in .5s $ease 0s forwards;
        }

        &__title,
        &__extension,
        &__icon {
            transition: color $duration $ease;
        }

        &__meta {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            animation: fade-in .5s $ease 0s;

            &:hover {

                &:before {
                    visibility: visible;
                    opacity: 1;
                }

                .attachment__title,
                .attachment__extension,
                .attachment__icon {
                    color: $white;
                }
            }

            &:before {
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.5);
                content: '';
                visibility: hidden;
                color: $white;
                transition: opacity $duration $ease, visibility $duration $ease;
            }
        }

        &__notification {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $black;

            animation: fade-in .5s $ease 0s forwards;

            img {
                width: 25px;
                margin: 10px;
            }
        }

        &__surtitle {
            text-transform: none;
            font-weight: bold;
            color: map_get($red, primary);
        }

        &__title {
            margin-bottom: 0;
            width: 100%;
            font-size: rem(13px);
            text-transform: none;
            font-weight: bold;
            color: $black;
        }

        &__extension {
            text-transform: uppercase;
        }

        &__icon {
            font-size: 1.5rem;
            padding: 1rem;

            i {
                color: inherit;
            }
        }

        &__delete {
            position: absolute;
            left: 25px;
            bottom: 0;
            padding: 0;
            border: none;
            color: map_get($grey, primary);
            cursor: pointer;
            transition: color $duration $ease;

            .icon {
                padding-right: 5px;
            }

            &:hover {
                color: map_get($red, primary);
            }
        }
    }

    .movement {
        position: relative;

        &__suggest {
          position: absolute;
          top: calc(100% - 1px);
          left: 0;
          width: 100%;
          border: 1px solid map_get($grey, medium);
          background-color: map_get($grey, xlight);
          padding: 6px 0;
        }

        &__suggest-item {
          appearance: none;
          border: 0;
          background-color: transparent;
          display: block;
          width: 100%;
          text-align: left;
          padding: 4px 30px;

          &:hover {
            color: map_get($red, primary);
            cursor: pointer;
          }
        }
    }
    /*
    Fix the border radius for request input (login input get radius)
     */
    input:-webkit-autofill {
        border-radius: 0 !important;
    }

    @import "nav";
    @import "print";
    @import "assign";
    @import "details";
    @import "customer";
    @import "breadcrumb";
    @import "actions";
    @import "title";
    @import "list";
    //@import "form";
}


