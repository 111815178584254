.pin {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background: map_get($blue, primary);
    line-height: 40px;
    color: $white;
    font: {
        size: rem(16px);
        weight: 400;
    };
    letter-spacing: 1px;
    text-align: center;

    &.add {
        background: transparent;
        border: 1px solid map_get($grey, medium);

        &:before {
            position: absolute;
            display: block;
            top: 20px;
            left: 20px;
            content: $icon-more;
            color: map_get($grey, medium);
            font: {
                family: $icomoon;
                size: rem(11px);
                weight: normal;
            }
            transform: translate(-50%, -50%);
        }
    }

    .hover {
        position: absolute;
        display: none;
        top: 55px;
        left: 50%;
        background: map_get($grey, dark);
        padding: 10px;
        line-height: 1;
        white-space: nowrap;
        font: {
            size: rem(12px);
            weight: 500;
        }
        transform: translateX(-50%);
        z-index: 3;

        &:before {
            display: block;
            position: absolute;
            content: '';
            height : 0;
            width : 0;
            border-right : 6px solid transparent;
            border-bottom : 10px solid map_get($grey, dark);
            border-left : 6px solid transparent;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }


    &:hover {
        .hover {
            display: inline-block;
        }
    }

    @each $index, $color in $userColors {
        &.user-#{$index} {
            background: $color;
        }
    }
}
