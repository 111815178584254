.grid {
    .form {
        display: flex;
        flex-wrap: wrap;
        max-width: 95%;
        margin: 0 -20px 0 -20px;
        padding: 0 60px 20px 60px;

        .field {
            padding: 0 20px;
            max-width: calc(100% / 4);
            flex-basis: calc(100% / 4);

        }

        .movement {
            position: relative;

            &__suggest {
              position: absolute;
              top: calc(100% - 1px);
              left: 20px;
              width: calc(100% - 40px);
              border: 1px solid map_get($grey, medium);
              background-color: map_get($grey, xlight);
              padding: 6px 0;
              z-index: 1;
            }

            &__suggest-item {
              appearance: none;
              border: 0;
              background-color: transparent;
              display: block;
              width: 100%;
              text-align: left;
              padding: 4px 30px;

              &:hover {
                color: map_get($red, primary);
                cursor: pointer;
              }
            }
        }

        select,
        input[type=date],
        .date-picker {
            font-size: rem(14px);
            letter-spacing: 1px;
            color: map_get($grey, primary);
            border-color: map_get($grey, xlight);
            background-color: map_get($grey, xlight);

            &::placeholder {
                color: map_get($grey, primary);
            }
        }
    }
    .custom-checkbox + label:before {
        width: 12px;
        height: 12px;
    }

    .bulk-select-all {
        .custom-checkbox + label:before {
            border: 2px solid map_get($red, primary);
        }
    }

}
