@font-face {
    font-family: 'omegact';
    src: url('../../public/fonts/omegact/bold/omegact-bold-webfont.eot');
    src: url('../../public/fonts/omegact/bold/omegact-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.woff2') format('woff2'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.woff') format('woff'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.ttf') format('truetype'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.svg#omega_ctbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'omegactbold';
    src: url('../../public/fonts/omegact/bold/omegact-bold-webfont.eot');
    src: url('../../public/fonts/omegact/bold/omegact-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.woff2') format('woff2'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.woff') format('woff'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.ttf') format('truetype'),
    url('../../public/fonts/omegact/bold/omegact-bold-webfont.svg#omega_ctbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'omegact';
    src: url('../../public/fonts/omegact/regular/omegact-regular-webfont.eot');
    src: url('../../public/fonts/omegact/regular/omegact-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/omegact/regular/omegact-regular-webfont.woff2') format('woff2'),
    url('../../public/fonts/omegact/regular/omegact-regular-webfont.woff') format('woff'),
    url('../../public/fonts/omegact/regular/omegact-regular-webfont.ttf') format('truetype'),
    url('../../public/fonts/omegact/regular/omegact-regular-webfont.svg#omega_ctregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'omegact';
    src: url('../../public/fonts/omegact/light/omegact-light-webfont.eot');
    src: url('../../public/fonts/omegact/light/omegact-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/omegact/light/omegact-light-webfont.woff2') format('woff2'),
    url('../../public/fonts/omegact/light/omegact-light-webfont.woff') format('woff'),
    url('../../public/fonts/omegact/light/omegact-light-webfont.ttf') format('truetype'),
    url('../../public/fonts/omegact/light/omegact-light-webfont.svg#omega_ctlight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:  url('../../public/fonts/icomoon/icomoon.eot?5ir3ff');
    src:  url('../../public/fonts/icomoon/icomoon.eot?5ir3ff#iefix') format('embedded-opentype'),
    url('../../public/fonts/icomoon/icomoon.ttf?5ir3ff') format('truetype'),
    url('../../public/fonts/icomoon/icomoon.ttf?5ir3ff') format('truetype'),
    url('../../public/fonts/icomoon/icomoon.woff2?5ir3ff') format('woff2'),
    url('../../public/fonts/icomoon/icomoon.woff?5ir3ff') format('woff'),
    url('../../public/fonts/icomoon/icomoon.svg?5ir3ff#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
