.pils {
    border-radius: 20px;
    padding: 5px 10px;
    border: none;
    text-align: center;
    font: {
        weight: 400;
        size: rem(13px);
    };
    letter-spacing: 1px;
    white-space: nowrap;

    @each $label, $color in $pilsStatus {
        &.#{$label} {
            background: map_get($color, light);
            color: map_get($color, primary);
        }
    }
    &.canceled {
        border: 1px solid map_get($grey, medium);
        background: transparent;
        color: map_get($grey, primary);
    }
}
