.flash-message {
    position: relative;
    width: 100%;
    display: none;
    //top: -5px;
    //left: 0;
    opacity: 0;
    padding: 20px 0;
    height: $flashMessageHeight;
    transition: opacity $duration $ease, top $duration $ease;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    z-index: 1;
    animation-duration: $duration;
    animation-name: flashmessage;

    &-wrapper {
        position: relative;
        margin: 0 auto;
        width: 100%;
        padding: {
            left: 140px;
            right: 140px;
        };
    }

    &-content {
        margin: 0;
    }

    @each $label, $color in $status {
        &.#{$label} {
            background: map_get($color, light);
            color: map_get($color, primary);
        }
    }

    &.bulk-actions-flashmessage {
        background: map_get($red, primary);
        color: $white;
        height: auto;

        p {
            margin: 0;
        }

        .icon-reject {
            color: #ffffff;
        }
    }

    &.open {
        opacity: 1;
        display: flex;
        align-items: center;
    }

    .icon-reject {
        position: absolute;
        right: 140px;
        top: 50%;
        transform: translateY(-50%);

        &:before {
            transition: transform $duration/2 ease-out;
        }

        &:hover {
            &:before {
                display: block;
                transform: rotate(90deg);
            }
        }
    }
}
