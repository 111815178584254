.list-wrapper {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    //overflow: auto;
    padding: 20px $bodyMargin;
}
.body-margin {
    min-width: $bodyMargin;

    &.extracts {
        min-width: 0;
    }
}
.list {
    //width: (100%/24)*20;
    width: 100%;
    border-spacing: 0 10px;
    animation-duration: $duration;
    animation-name: fade-in;

    thead {
        tr {
            background: transparent;

            th {
                position: relative;
                cursor: pointer;
                padding: 0 32px 0 16px;
                height: 50px;
                text-align: left;
                text-transform: uppercase;
                color: map_get($red, primary);
                font: {
                    weight: bold;
                    size: rem(12 );
                }
                letter-spacing: 1px;
                white-space: nowrap;

                &:first-child {
                    padding: 0 36px 0 18px;

                    &:after {
                        right: 10px;
                    }
                }

                &:after {
                    opacity: 0;
                    position: absolute;
                    top: 15px;
                    right: 50px;
                    content: $icon-triangle;
                    font: {
                        family: $icomoon;
                        weight: normal;
                        size: 1em;
                    }
                    color: map_get($red, primary);
                    transform: rotate(-180deg);
                    transition: transform $duration $ease, opacity $duration $ease;
                }

                &[data-direction=asc] {
                    color: map_get($red, primary);

                    &:after {
                        opacity: 1;
                        transform: rotate(0deg);
                    }
                }

                &[data-direction=desc] {
                    color: map_get($red, primary);

                    &:after {
                        opacity: 1;
                    }
                }

                &.center {
                    text-align: center;
                }
            }
        }
    }

    tbody {
        tr {
            @extend .row-shadow;
            border-radius: $tableRadius;

            &:hover {
                box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
            }

            &.new {
                td:first-child {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: $gridRowHeight;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        border-radius: $tableRadius 0 0 $tableRadius;
                        z-index: 1;
                    }
                }
            }

            &.new {
                td:first-child {
                    &:before {
                        background: map_get($red, primary);
                    }
                }
            }

            &.selected {
                td {
                    background: $white;
                }
            }

            &.unselected {
                td {
                    background: map_get($grey, light);
                }
            }

            td {
                position: relative;
                cursor: pointer;
                height: $gridRowHeight;
                padding: 0 32px 0 16px;
                //border: 1px solid $white;
                text-align: left;
                font: {
                    size: rem(13);
                    weight: 500;
                }
                letter-spacing: 0.5px;
                white-space: nowrap;

                background: $white;
                transition: background $duration $ease;

                &:first-child {
                    border-radius: $tableRadius 0 0 $tableRadius;
                    position: relative;
                    padding: 0 36px 0 18px;
                }

                &:last-child {
                    border-radius: 0 $tableRadius $tableRadius 0;
                }

                &.center {
                    text-align: center;
                }

                .date {
                    //font-size: rem(15px);
                }
                .hours {
                    //display: block;
                    //margin-top: 5px;
                    margin-left: 5px;
                }
                .new {
                    text-transform: capitalize;
                }

                .user-listing {
                    position: absolute;
                    width: 225px;
                    border-radius: 7px;
                    background: map_get($grey, xlight);
                    z-index: 20;
                    right: 110px;
                    top: 50%;
                    padding: 8px 15px;
                    text-align: left;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(-50%);
                    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
                    transition: opacity $duration, visibility $duration;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: -12px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 0 10px 15px;
                        border-color: transparent transparent transparent map_get($grey, xlight);
                        transform: translateY(-50%);
                    }
                }

                &.menu-open {
                    .user-listing {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .user-item {
                    padding: 7px 0;
                }

                .user-item-link {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .pin {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: rem(12px);
                    }

                    &-name {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    &-wide {
        thead, tbody {
            tr {
                th, td {
                    padding: 0 30px;

                    &:first-child {
                        padding-left: 60px;
                    }

                    &:last-child {
                        padding-right: 60px;
                    }
                }
            }
        }
    }

    &-offset-right {
        thead, tbody {
            tr, th {
                td:last-child {
                    padding-right: 300px;
                }
            }
        }
    }
}

.total {
    font-size: rem(14px);

    .separator {
        height: 40px;
    }

    .total-wrapper {
        background: map_get($grey, light);
        padding: 20px;

        > table {
            margin: 0 0 0 auto;
            white-space: nowrap;
            border-collapse: collapse;
            border-spacing: 0;
        }

        th {
            text-align: left;
            padding-right: 40px;
        }

        td {
            text-align: right;
            min-width: 120px;
        }

        td,
        th {
            height: 40px;
            vertical-align: middle;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 40px;
        }

        .label {
            display: inline;
            color:inherit;
            height: auto;
        }
    }

    &-title,
    &-value {
        font-size: rem(18px);
    }
}
