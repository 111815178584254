.header {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: $headerHeight;
    top: 0;
    left: 0;
    background: $white;
    justify-content: space-between;
    z-index: 10;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);

    &-wrapper {

    }

    &-logo {
        margin-left: 40px;
        display: flex;

        img {
            width: 70px;
            height: 70px;
        }
    }

    &--center {
        justify-content: center;

        img.header-logo {
            width: 70px;
            height: auto;
        }
    }

    .nav-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .nav {
        > li {
            display: inline-block;
            height: $headerHeight;
            line-height: $headerHeight;
            margin: 0 20px;
            position: relative;

            &:hover .submenu {
                display: block;
            }

            > span,
            > a {
                display: block;
                position: relative;
                height: 100%;
                font: {
                    family: $omegaCt;
                    weight: bold;
                }
                text-transform: uppercase;
                color: map_get($grey, primary);
                transition: color $duration $ease;


                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    background: transparent;
                    transition: height $duration $ease, background $duration $ease;
                }

                &.active,
                &:hover {
                    color: map_get($red, primary);

                    &:after {
                        height: 3px;
                        background: map_get($red, primary);
                    }
                }
            }

            .submenu {
                display: none;
                position: absolute;
                padding-top: 20px;
                width: auto;
                top: 100%;
                left: -40px;
                background: $white;
                border: 1px solid map_get($grey, light);
                animation-duration: $duration;
                animation-name: header-submenu;

                a {
                    display: block;
                    padding: 0 40px;
                    color: map_get($grey, primary);
                    white-space: nowrap;
                    font: {
                        size: rem(13px);
                    }
                    line-height: 40px;

                    &.active,
                    &:hover {
                        color: map_get($red, primary);
                    }
                }

                .new-request {
                    margin-top: 10px;
                    background: map_get($red, primary);

                    a {
                        position: relative;
                        padding: 10px 40px;
                        color: $white;

                        &:before {
                            position: absolute;
                            left: 25px;
                            top: 10px;
                            content: '+';
                            color: transparent;
                            transition: color $duration;
                        }

                        &:hover {
                            &:before {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .user {
        position: relative;
        padding: {
            left: 40px;
            right: 40px;
        }
        margin-left: 20px;
        border-left: 1px solid map_get($grey, light);

        &-link {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            color: map_get($grey, primary);
        }

        &-name {
            margin-left: 20px;

            strong {
                display: block;
            }
        }

        &:hover {
            .user-menu {
                display: block;
            }
        }

        &-menu {
            display: none;
            position: absolute;
            width: calc(100% + 1px);
            top: 100%;
            left: -1px;
            background: $white;
            border-top: 1px solid map_get($grey, light);
            border-left: 1px solid map_get($grey, light);
            border-bottom: 1px solid map_get($grey, light);
            padding: 10px 20px 10px 40px;
            animation-duration: $duration;
            animation-name: header-submenu;

            li {
                &:hover {
                    color: map_get($red, primary);

                    a {
                        color: map_get($red, primary);
                    }
                }

                &:before {
                    font-family: $icomoon;
                    margin-right: 10px;
                    display: inline-block;
                    padding: {
                        top: 5px;
                        bottom: 5px;
                    }
                }
            }
            a {
                color: map_get($grey, primary);
            }
        }

        &-profil {
            &:before {
                content: $icon-profile;
            }
        }
        &-logout {
            &:before {
                content: $icon-logout;
            }
        }
    }
}
