body {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: {
        top: 0;
        bottom: 60px;
        right: 0;
        left: 0;
    }
    padding: {
        top: $headerHeight;
    }
    background: map_get($grey, xlight);
    color: map_get($grey, primary);
    font-family: $omegaCt;
    letter-spacing: 1px;
    line-height: 1.4em;
    transition: padding $duration $ease;

    &.flash-message-open {
        padding: {
            top: $headerHeight + $flashMessageHeight;
        }
    }

    &.bulk-actions-open {
        padding: {
            bottom: $bulkActionsHeight;
        }
    }
}

@for $i from 1 through $grid {
    .width-#{$i} {
        width: 100% / $grid * $i;
    }
}

.top {
    position: fixed;
    top: 70px;
    width: 100vw;
    z-index: 5;
}

:focus {
    outline: none !important;
}

.row-shadow {
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.05);
}

h1,
.title-h1 {
    color: map_get($red, primary);
    display: block;
    margin: 0;
    font: {
        size: rem(38px);
        weight: normal;
    }
    text-transform: uppercase;
    letter-spacing: 2px;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.6em;
}

.hidden {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.upper {
    text-transform: uppercase;
}

.red {
    color: map_get($red, primary);
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.col-left-right {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
}

.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.buttons {
    margin-top: 30px;

    .button {
        margin-right: 30px;
    }
}

@import "header";
