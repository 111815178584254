.request-breadcrumb {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    &-list {
        margin: {
            left: 15px;
        }
        line-height: rem(32px);

        li {
            display: inline-block;
            font: {
                size: rem(14px);
            }

            &:before {
                content: $icon-arrow-right;
                font: {
                    family: $icomoon;
                    size: rem(12px);
                }
                margin: {
                    left: 10px;
                    right: 10px;
                }
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }

            &:last-child {
                &:before {
                    color: map_get($grey, primary);
                }
                color: map_get($red, primary);
            }
        }

    }
}
