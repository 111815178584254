@function stripUnit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

@function rem($size) {
    $remSize: (stripUnit($size) / stripUnit(16px));
    @return #{$remSize}rem;
}
